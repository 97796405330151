<template>
  <div class="app_con">
    <app-list
      :remote="remote"
      :props="props"
      :formProps="formProps"
      :searchQuery="searchQuery"
      :searchControl="searchControl"
      :apiName="apiName"
      :rules="rules"
      :editTitle="'字典'"
      :isInline="true"
      :btnFlag="{ addShow: true, editShow: true, deleteShow: true }"
    >
      <div slot="toolSub">
        <el-button
          type="success"
          icon="el-icon-back"
          @click="jumpPage"
          size="small"
          >返回字典列表</el-button
        >
      </div>
      <div class="search" slot="tool">
        <el-input
          placeholder="按名称查询"
          v-model="searchQuery.name"
          size="small"
          class="searchInput"
        >
          <template slot="prepend">名称</template>
        </el-input>

        <el-input
          placeholder="按类型查询"
          v-model="searchQuery.type"
          size="small"
          class="searchInput"
        >
          <template slot="prepend">类型</template>
        </el-input>
      </div>
    </app-list>
  </div>
</template>

<script>
import api from "@/api";

export default {
  data() {
    // let self = this;
    return {
      remote: api.common,
      apiName: "/systemDataDictionaryItem",
      searchVal: "",

      bntLoading: false,
      searchControl: {
        firstSoltSpan: [5, 5, 5, 5],
        secondSoltSpan: [3, 3, 3, 3],
        thirdSoltSpan: [14, 14, 14, 14],
      },
      searchQuery: {
        systemDataDictionaryId: this.$route.query.id,
        jobCode: "",
        status: "",
      },
      rules: {
        //传递给APPlist的表单验证
        name: [
          {
            required: true,
            message: "请输入名称",
            trigger: ["blur", "change"],
          },
        ],
        type: [
          {
            required: true,
            message: "请输入类型",
            trigger: ["blur", "change"],
          },
        ],
      },
      props: [
        {
          label: "字典编码",
          prop: "parameterCode",
          align: "center",
          width: 100,
        },
        {
          label: "字典标签",
          prop: "parameterName",
          align: "center",
          width: 200,
        },

        {
          label: "字典键值",
          prop: "parameterValue",
          align: "center",
          width: 100,
        },

        {
          label: "排序",
          prop: "seqNo",
          align: "center",
          width: 200,
        },

        {
          label: "备注",
          prop: "remark",
          align: "center",
        },
        {
          label: "操作",
          align: "center",
          type: "operationLink",
          width: 300,
          formatter: function(row) {
            const buttonList = [
              {
                text: "编辑",
                size: "mini",
                data: row,
                show: true,
                icon: "el-icon-edit",
                type: "primary",
                handlerType: "update",
                handler: function() {},
              },
              {
                text: "删除",
                size: "mini",
                data: row,
                show: true,
                icon: "el-icon-delete",
                type: "danger",
                handlerType: "remove",
                handler: function() {},
              },
            ];
            return buttonList;
          },
        },
      ],
      formProps: [
        {
          label: "字典ID",
          prop: "systemDataDictionaryId",
          type: "input",
          default: this.$route.query.id,
          disabled:true
        },
        {
          label: "字典编码",
          prop: "parameterCode",
          type: "input",
        },
        {
          label: "字典标签",
          prop: "parameterName",
          type: "input",
        },

        {
          label: "字典键值",
          prop: "parameterValue",
          type: "input",
        },
        {
          label: "排序",
          prop: "seqNo",
          type: "input",
        },
        {
          label: "备注",
          prop: "remark",
          type: "input",
        },
      ],
    };
  },
  created() {},
  methods: {
    //同步默认商品到商户

    jumpPage() {
      this.$router.push({ path: "/system/dictionaryList" });
    },
  },
};
</script>

<style lang="scss" scoped>
.search {
  display: flex;
}
.el-select {
  /deep/ .el-input__inner {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
}
</style>
